<template>
  <v-container>
    <BaseForm
      :title="$route.meta.title"
      :loading="loading"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <!-- RADIO BUTTONS -->
        <v-row>
          <v-col cols="12" md="12">
            <v-radio-group
              v-model="user.register_type"
              @change="handleRegister"
              class=""
              :row="$vuetify.breakpoint.mdAndUp"
            >
              <v-radio label="Asistido" value="Assisted"></v-radio>
              <v-radio label="Manual" value="Manual"></v-radio>
              <v-radio
                label="Administrador"
                value="Administrator"
                :disabled="hasCustomerRole"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <!-- USUARIO ASISTIDO -->
        <v-row v-if="user.register_type !== 'Administrator'">
          <v-col cols="12" md="6" class="" v-if="category.client">
            <ValidationProvider
              name="Número de Cuenta"
              rules="required"
              v-slot="{ errors }"
            >
              <v-autocomplete
                class=""
                label="Especifique el número de cuenta"
                append-icon="mdi-magnify"
                dense
                single-line
                light
                :items="accounts.accounts"
                item-text="code"
                item-value="code"
                @input="handleAccount"
                v-model="objects.accountCode.code"
                autofocus
                :error-messages="errors[0]"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" class="" v-else>
            <!-- <ValidationProvider
              name="Número de Cuenta"
              rules="required"
              v-slot="{ errors }"
            > -->
            <v-autocomplete
              class=""
              label="Especifique el número de cuenta"
              append-icon="mdi-magnify"
              hide-details
              dense
              single-line
              light
              :items="accounts.accounts"
              item-text="code"
              item-value="code"
              @input="handleAccount"
              v-model="objects.accountCode.code"
            ></v-autocomplete>
            <!-- :error-messages="errors[0]" -->
            <!-- </ValidationProvider> -->
          </v-col>
          <v-col
            cols="12"
            md="6"
            class=""
            v-if="user.register_type === 'Assisted'"
          >
            <v-select
              :items="accountBeneficiaries"
              :label="accountBeneficiariesLabel"
              :loading="loadingBeneficiaries"
              :disabled="fields.beneficiaries"
              v-model="accountBeneficiariesSelected"
              @input="handleAssisted"
              class=""
              hide-details
              dense
              single-line
              light
            >
              <!-- Slot del item seleccionado -->
              <template #selection="{ item }">
                {{ item.firstname }} {{ item.lastname }}
              </template>
              <!-- Slot del Listado -->
              <template #item="{ item }">
                {{ item.firstname }} {{ item.lastname }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!-- DATOS BASICOS -->
        <div class="mt-6">
          <!-- Datos Basicos Titulo -->
          <v-row>
            <v-col>
              <span class="font-weight-bold">Datos Básicos</span>
              <span class="font-italic ml-3">* Campos requeridos</span>
            </v-col>
          </v-row>
          <!-- Formulario de Datos Basicos Usuario -->
          <div class="mt-6">
            <!-- Nombres y Apellidos -->
            <v-row>
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="Nombres"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Nombres *"
                    placeholder="Nombres"
                    v-model="user.first_name"
                    dense
                    :error-messages="errors[0]"
                    autocomplete="nope"
                    :disabled="fields.global && accountBeneficiariesSelected.firstname !== ''"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="Apellidos"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Apellidos *"
                    placeholder="Apellidos"
                    v-model="user.last_name"
                    dense
                    :error-messages="errors[0]"
                    autocomplete="nope"
                    :disabled="fields.global && accountBeneficiariesSelected.lastname !== ''"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <!-- identificacion y Pasaporte -->
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Identificación"
                  placeholder="Identificación"
                  v-model="user.identification"
                  dense
                  autocomplete="nope"
                  :disabled="fields.global && accountBeneficiariesSelected.documentId !== ''"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Pasaporte"
                  placeholder="Pasaporte"
                  v-model="user.passport"
                  dense
                  autocomplete="nope"
                  :disabled="fields.global && accountBeneficiariesSelected.passport !== null"
                />
              </v-col>
            </v-row>
            <!-- Direccion -->
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Dirección"
                  placeholder="Dirección"
                  v-model="user.address"
                  dense
                  autocomplete="nope"
                  :disabled="fields.global && accountBeneficiariesSelected.address1 !== undefined"
                />
              </v-col>
              <!-- Nacionalidad -->
              <!-- ideal autocomplete con valores de nacionalidad comunes a la bdd de AV -->
              <v-col cols="12" md="6">
                <v-autocomplete
                  label="Nacionalidad"
                  placeholder="Nacionalidad"
                  v-model="user.nationality"
                  dense
                  clearable
                  :disabled="fields.global && accountBeneficiariesSelected.nationality !== ''"
                  :items="countries.countries"
                  item-text="name_es"
                  item-value="name_es"
                >
                  <!-- Seleccion -->
                  <template v-slot:selection="data">
                    {{ data.item.name_es }} - {{ data.item.code }}
                  </template>
                  <!-- listado -->
                  <template #item="{ item }">
                    {{ item.name_es }} - {{ item.code }}
                  </template>
                </v-autocomplete>
                <!-- autocomplete="nope" -->
              </v-col>
            </v-row>
            <!-- Telefono Fijo y Telefono Movil -->
            <v-row>
              <v-col cols="12" md="6">
                <!-- <code>{{user.phone}}</code> -->
                <phone-input
                  label="Teléfono Fijo"
                  placeholder="Teléfono Fijo"
                  v-model="user.phone"
                  dense
                  :disabled="fields.global && accountBeneficiariesSelected.phoneNumber !== null"
                />
              </v-col>
              <v-col cols="12" md="6">
                <!-- <code>{{user.mobile_phone}}</code> -->
                <phone-input
                  label="Teléfono Móvil"
                  placeholder="Teléfono Móvil"
                  v-model="user.mobile_phone"
                  dense
                />
              </v-col>
            </v-row>
            <!-- Correo Electrónico y Confirmacion de Correo -->
            <v-row>
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="correo"
                  rules="required|email|confirmed:fields.email_confirmation|max:255"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Correo Electrónico *"
                    placeholder="Correo Electrónico"
                    v-model="user.email"
                    dense
                    :error-messages="errors[0]"
                    autocomplete="nope"
                    :disabled="user.id !== undefined"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="confirm correo"
                  v-slot="{ errors }"
                  rules="required|email|max:255"
                  vid="fields.email_confirmation"
                >
                  <v-text-field
                    label="Confirmación Correo Electrónico *"
                    placeholder="Confirmación Correo Electrónico"
                    v-model="fields.email_confirmation"
                    dense
                    :error-messages="errors[0]"
                    autocomplete="nope"
                    :disabled="user.id !== undefined"
                    @click.right.prevent
                    @copy.prevent
                    @paste.prevent
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="user.register_type !== 'Administrator'">
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="Broker"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="brokers"
                    v-model="user.broker_id"
                    @input="handleGroups"
                    item-text="name"
                    item-value="id"
                    label="Seleccione el Broker"
                    dense
                    single-line
                    light
                    :error-messages="errors[0]"
                    clearable
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <!-- Correo Electrónico alternativo y Confirmacion de Correo -->
          </div>
          <!-- Roles de Usuario Titulo -->
          <!-- Roles de Usuario Casilla -->
          <div class="mt-6">
            <v-tabs active-class="tab-active">
              <v-tab v-if="!fields.admin_check">
                Roles Área Pública
              </v-tab>
              <v-tab>
                Roles Administradores
              </v-tab>
              <v-tab-item v-if="!fields.admin_check">
                <v-checkbox
                  v-for="roleClient in roles.rolesClient"
                  :key="roleClient.name"
                  :label="roleClient.name"
                  :value="roleClient"
                  v-model="user.roles"
                  class="mt-1"
                ></v-checkbox>
              </v-tab-item>
              <v-tab-item>
                <v-checkbox
                  v-for="roleAdmin in roles.rolesAdmin"
                  :key="roleAdmin.name"
                  :label="roleAdmin.name"
                  :value="roleAdmin"
                  v-model="user.roles"
                  class="mt-1"
                ></v-checkbox>
              </v-tab-item>
            </v-tabs>
          </div>
          <v-row class="mt-1" v-if="user.register_type !== 'Administrator'">
            <v-col>
              <span class="font-weight-bold">Categorías</span>
            </v-col>
          </v-row>
          <!-- Categorias inputs -->
          <div v-if="user.register_type !== 'Administrator'">
            <!-- Cliente -->
            <v-row class="category_row">
              <v-col
                cols="12"
                md="4"
                class="d-flex flex-row justify-space-between align-center"
              >
                <v-checkbox
                  label="Cliente"
                  v-model="category.client"
                  @click="handleClientCheckbox"
                ></v-checkbox>
              </v-col>
            </v-row>
            <!-- Autoridad -->
            <v-row class="">
              <v-col
                cols="12"
                md="4"
                class="d-flex flex-row justify-space-between align-center"
              >
                <v-checkbox
                  label="Autoridad"
                  v-model="category.authority"
                  @change="handleAuthorityCheckbox"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="category.authority">
              <v-col cols="12" md="6">
                <!--<code>{{user.authorityLevelsCode}}</code>-->
                <v-autocomplete
                  :items="authorityLevels"
                  item-text="name"
                  item-value="code"
                  @input="handleAuthorityLevels"
                  v-model="user.authorityLevelsCode"
                  class="pa-3"
                  label="Seleccione Sub Categoría"
                  hide-details
                  dense
                  single-line
                  light
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <!--<code>{{ objects.authorityCode.code }}</code>-->
                <ValidationProvider
                  name="Grupo"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :filter="filterObject"
                    :items="authorities"
                    :loading="loadingAuthorities"
                    :label="authoritiesLabel"
                    :disabled="fields.authorities"
                    item-value="code"
                    class="pa-3"
                    dense
                    single-line
                    light
                    v-model="objects.authorityCode.code"
                    :error-messages="errors[0]"
                  >
                    <!-- Seleccion -->
                    <template v-slot:selection="data">
                      {{ data.item.firstname }} {{ data.item.lastname }}
                    </template>
                    <!-- listado -->
                    <template #item="{ item }">
                      {{ item.firstname }} {{ item.lastname }}
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
            <!-- Grupo -->
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="d-flex flex-row justify-space-between align-center"
              >
                <v-checkbox label="Grupo" v-model="category.group"></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="category.group">
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="Grupo"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="accountGroups"
                    :loading="loadingGroups"
                    v-model="objects.groupCode.code"
                    @input="handleGroups"
                    item-text="name"
                    item-value="code"
                    class="pa-3"
                    label="Seleccione Grupo"
                    dense
                    single-line
                    light
                    :error-messages="errors[0]"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2" class="d-flex justify-start">
                <v-select
                  :items="languages"
                  label="Lenguaje"
                  dense
                  item-text="name"
                  item-value="code"
                  prepend-icon="mdi-web"
                  v-model="user.languageCode"
                ></v-select>
              </v-col>
              <v-col cols="12" md="10" class="d-flex justify-end">
                <v-switch
                  label="Activo"
                  v-model="user.active"
                  color="primary"
                ></v-switch>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import UserService from '@/services/user'
import RoleService from '@/services/role'
import AccountService from '@/services/external/accounts'
import AccountBeneficiariesService from '@/services/external/accountBeneficiaries'
import AuthorityLevelsService from '@/services/external/authorityLevels'
import AuthoritiesService from '@/services/external/authorities'
import AccountGroupsService from '@/services/external/accountGroups'
import PhoneInput from '@/components/commons/PhoneInput'
import Countries from '@/api/countries'
import LanguageService from '@/services/language'
import BrokerService from '@/services/broker'

export default {
  components: { BaseForm, PhoneInput },
  data() {
    return {
      loading: false,
      user: {
        password: '',
        roles: [],
        register_type: 'Assisted',
        active: true,
        first_name: '',
        last_name: '',
        address: '',
        passport: '',
        identification: '',
        nationality: '',
        avobjects: [],
        authorityLevelsCode: null,
        languageCode: 'es',
        broker_id: null
      },
      fields: {
        first_name: false,
        last_name: false,
        address: false,
        passport: false,
        identification: false,
        nationality: false,
        global: true,
        beneficiaries: true,
        only_admin: true,
        authorities: true,
        admin_radio_check: false,
        email_confirmation: '',
        email_confirmation_alternartive: '',
        admin_check: false
      },
      objects: {
        accountCode: {
          code: '',
          typeCode: 'accountCode'
        },
        groupCode: {
          code: '',
          typeCode: 'groupCode'
        },
        authorityCode: {
          code: '',
          typeCode: 'authorityCode'
        }
      },
      roles: {
        rolesAdmin: [],
        rolesClient: []
      },
      accounts: {
        accounts: []
      },
      accountBeneficiaries: [],
      loadingBeneficiaries: false,
      accountBeneficiariesSelected: {},
      authorityLevels: [],
      loadingAuthorities: false,
      loadingGroups: false,
      authorities: [],
      accountGroups: [],
      category: {
        client: true,
        authority: false,
        group: false
      },
      countries: [],
      languages: [],
      brokers: []
    }
  },
  async created() {
    this.loading = true
    try {
      const countries = Countries
      this.countries = countries
      await this.populateRolesAdmin()
      await this.populateRolesClient()
      await this.populateAccounts()
      await this.populateAuthorityLevels()
      await this.populateAccountGroups()
      await this.populateLanguages()
      await this.populateBrokers()
      const id = this.$route.params.id
      //Edit
      if (id) {
        this.user = await UserService.find(id)
        this.user.roles = this.user.roles.map(item => {
          return { name: item.name, type: item.type }
        })
        this.fields.email_confirmation = this.user.email
        this.fields.email_confirmation_alternartive = this.user.email_alternative
        if (this.user.register_type === 'Manual') {
          this.fields.global = false
        }
        if (this.user.register_type === 'Administrator') {
          this.fields.global = false
          this.admin_check = true
        }
        await this.populateAvobjectsEdit(this.user.avobjects)
        this.handleRegisterType()
      }
    } catch (error) {
      console.log(error)
      this.$dialog.message.error('No se pudo obtener los datos de las cuentas')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async handleSubmit() {
      // this.loading = true
      try {
        // refactorizar
        await this.adminBoolean()
        await this.clientBoolean()
        if (this.category.client || this.category.authority || this.category.group) {
          if (!this.user.customer) {
            return this.$dialog.message.error(
              'No ha escogido un rol de cliente para el usuario'
            )
          }
        }
        if (this.user.customer) {
          if (!this.category.client && !this.category.authority && !this.category.group) {
            return this.$dialog.message.error(
              'No ha escogido una categoría para el usuario'
            )
          }
        }
        this.user.avobjects = []
        if (this.category.client && this.objects['accountCode'].code) {
          this.user.avobjects.push(this.objects['accountCode'])
        }
        if (this.category.authority && this.objects['authorityCode'].code) {
          this.user.avobjects.push(this.objects['authorityCode'])
        }
        if (this.category.group && this.objects['groupCode'].code) {
          this.user.avobjects.push(this.objects['groupCode'])
        }
        if (this.user.email != this.user.email_alternative) {
          if (this.user.customer && this.user.admin) {
            const res = await this.$dialog.warning({
              text:
                '¿ Desea registrar este usuario ? Tiene roles de Área Pública y Administrador.'
            })
            if (res) {
              this.loading = true
              await UserService.save(this.user)
              if (!this.user.id) {
                this.$dialog.notify.success(
                  'Registro guardado con éxito. Correo enviado al usuario'
                )
              } else {
                this.$dialog.notify.success('Registro guardado con éxito.')
              }
              this.$router.go(-1)
            }
          } else {
            this.loading = true
            if (this.user.roles.length == 0) {
              this.$dialog.message.error('No ha escogido roles para el usuario')
            } else {
              this.loading = true
              await UserService.save(this.user)
              if (!this.user.id) {
                this.$dialog.notify.success(
                  'Registro guardado con éxito. Correo enviado al usuario'
                )
              } else {
                this.$dialog.notify.success('Registro guardado con éxito.')
              }
              this.$router.go(-1)
            }
          }
        } else {
          this.$dialog.message.error(
            'Correo Electrónico y Correo Electrónico alternativo no pueden coincidir'
          )
        }
      } catch (e) {
        console.log(e)
        this.$dialog.message.error('No se pudo guardar el registro.')
      }
      this.loading = false
    },
    async populateRolesAdmin() {
      const { data } = await RoleService.fetch({ 'filter[type]': 'Admin' })
      this.roles.rolesAdmin = data.data.map(item => {
        return { name: item.name, type: item.type }
      })
    },
    async populateRolesClient() {
      const { data } = await RoleService.fetch({ 'filter[type]': 'Customer' })
      this.roles.rolesClient = data.data.map(item => {
        return { name: item.name, type: item.type }
      })
    },
    async adminBoolean() {
      this.user.admin = this.user.roles.some(item => item.type === 'Admin')
    },
    async clientBoolean() {
      this.user.customer = this.user.roles.some(
        item => item.type === 'Customer'
      )
    },
    async populateAccounts() {
      const { data } = await AccountService.fetch()
      this.accounts.accounts = data
    },
    async populateBrokers() {
      const { data } = await BrokerService.fetch()
      this.brokers = data.data
    },
    async handleAccount(code) {
      if (code) {
        if (!this.user.id && this.user.register_type === 'Assisted') {
          this.loadingBeneficiaries = true
          this.fields.beneficiaries = true
          this.accountBeneficiariesLabel = `Lista de Beneficiarios`
          this.accountBeneficiaries = []
          this.accountBeneficiariesSelected = {}
          this.user.first_name = ''
          this.user.last_name = ''
          this.user.address = ''
          this.user.passport = ''
          this.user.identification = ''
          this.user.nationality = ''
          this.user.phone = ''
        }
        try {
          this.accountBeneficiaries = await AccountBeneficiariesService.find(
            code
          )
          this.objects.accountCode.code = code
          this.objects.accountCode.typeCode = 'accountCode'
          let account = this.user.avobjects.find(
            obj => obj.typeCode === 'accountCode'
          )
          if (!account) {
            this.user.avobjects.push(this.objects.accountCode)
          }
        } catch (error) {
          console.log(error)
          this.$dialog.message.error('No se pudo obtener beneficiarios')
        } finally {
          this.loadingBeneficiaries = false
          this.fields.beneficiaries = false
        }
      } else {
        this.loadingBeneficiaries = false
        this.fields.beneficiaries = true
        this.accountBeneficiariesLabel = `Lista de Beneficiarios`
        this.accountBeneficiaries = []
        this.accountBeneficiariesSelected = {}
        this.user.first_name = ''
        this.user.last_name = ''
        this.user.address = ''
        this.user.passport = ''
        this.user.identification = ''
        this.user.nationality = ''
        this.user.phone = ''
      }
    },
    async populateAuthorityLevels() {
      const { data } = await AuthorityLevelsService.fetch()
      this.authorityLevels = data
    },
    async handleAuthorityLevels(code) {
      this.loadingAuthorities = true
      if (code || code === 0) {
        try {
          this.authorities = []
          const { data } = await AuthoritiesService.fetch({
            authorityLevelCode: code.toString()
          })
          this.authorities = data
        } catch (error) {
          console.log(error)
          this.$dialog.message.error('No se pudo obtener autoridades')
        } finally {
          this.loadingAuthorities = false
          this.fields.authorities = false
        }
      } else {
        this.loadingAuthorities = false
        this.authorities = []
        this.fields.authorities = true
      }
    },
    handleAuthorityCheckbox(check) {
      if (!check) {
        this.authorities = []
        this.user.authorityLevelsCode = null
      } 
    },
    async handleAssisted() {
      //Nombre
      this.user.first_name = this.accountBeneficiariesSelected.firstname

      //Apellido
      this.user.last_name = this.accountBeneficiariesSelected.lastname

      //Direccion
      this.user.address = this.accountBeneficiariesSelected.address1

      //Pasaporte
      this.user.passport = this.accountBeneficiariesSelected.passport

      //Identificacion
      this.user.identification = this.accountBeneficiariesSelected.documentId

      //Nacionalidad
      this.user.nationality = this.accountBeneficiariesSelected.nationality

      //Telefono
      this.user.phone = this.accountBeneficiariesSelected.phoneNumber

      //Email
      if (!this.user.id) {
        const email = this.accountBeneficiariesSelected.email
        this.$set(this.user, 'email', email)
        this.fields.email_confirmation = this.user.email
      }
    },
    async handleRegisterHelper() {
      if (!this.user.id) {
        //clear fields
        this.user.broker_id = null
        this.user.first_name = ''
        this.user.last_name = ''
        this.user.address = ''
        this.user.passport = ''
        this.user.identification = ''
        this.user.nationality = ''
        this.user.phone = ''
        this.user.mobile_phone = ''
        this.user.email = ''
        this.fields.email_confirmation = ''
        this.user.email_alternative = ''
        this.fields.email_confirmation_alternartive = ''
        this.objects.accountCode.code = ''
        this.accountBeneficiaries = []
        this.accountBeneficiariesSelected = {}
        this.user.roles = []
      }
    },
    async handleRegister() {
      this.handleRegisterHelper()
      if (this.user.register_type === 'Administrator') {
        this.fields.global = false
        this.fields.admin_check = true
        this.category.client = false
        if (!this.user.id) {
          this.category.authority = false
          this.category.group = false
          this.fields.beneficiaries = true
          this.user.authorityLevelsCode = null
          this.objects.authorityCode.code = ''
          this.authorities = ''
          this.fields.authorities = true
          this.objects.groupCode.code = ''
        }
      }
      if (this.user.register_type === 'Assisted') {
        this.fields.global = true
        this.fields.admin_check = false
        if (!this.user.id) {
          this.category.client = true
          this.category.client = true
          this.category.authority = false
          this.category.group = false
          this.fields.beneficiaries = true
          this.user.authorityLevelsCode = null
          this.objects.authorityCode.code = ''
          this.authorities = ''
          this.fields.authorities = true
          this.objects.groupCode.code = ''
        }
      }
      if (this.user.register_type === 'Manual') {
        this.fields.global = false
        this.fields.admin_check = false
        if (!this.user.id) {
          this.category.client = true
          this.category.client = true
          this.category.authority = false
          this.category.group = false
          this.fields.beneficiaries = true
          this.user.authorityLevelsCode = null
          this.objects.authorityCode.code = ''
          this.authorities = ''
          this.fields.authorities = true
          this.objects.groupCode.code = ''
        }
      }
    },
    handleRegisterType() {
      if (this.user.register_type === 'Administrator') {
        this.fields.global = false
        this.fields.admin_check = true
        this.category.client = false
      }
      if (this.user.register_type === 'Assisted') {
        this.fields.global = true
        this.fields.admin_check = false
      }
      if (this.user.register_type === 'Manual') {
        this.fields.global = false
        this.fields.admin_check = false
      }
    },
    async populateAccountGroups() {
      const { data } = await AccountGroupsService.fetch()
      // data.sort((a, b) => a.name - b.name)
      data.sort(function(a, b) {
        let nameA = a.name.toUpperCase() // ignore upper and lowercase
        let nameB = b.name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        // names must be equal
        return 0
      })
      this.accountGroups = data
    },
    async populateLanguages() {
      const { data } = await LanguageService.fetch()
      this.languages = data.data
    },
    async handleAuthorities(code) {
      this.objects.authorityCode.code = code
      let authority = this.user.avobjects.find(
        obj => obj.typeCode === 'authorityCode'
      )
      if (!authority) {
        this.user.avobjects.push(this.objects.authorityCode)
      }
    },
    async handleGroups(code) {
      this.objects.groupCode.code = code
      this.objects.groupCode.typeCode = 'groupCode'
      let group = this.user.avobjects.find(obj => obj.typeCode === 'groupCode')
      if (!group) {
        this.user.avobjects.push(this.objects.groupCode)
      }
    },
    async populateRolesEdit(roles) {
      if (roles) {
        let roles_name = []
        roles.forEach(role => {
          roles_name.push(role.name)
        })
        this.user.roles = roles_name
      }
    },
    async populateAvobjectsEdit(avobjects) {
      if (avobjects) {
        let account = avobjects.find(obj => obj.typeCode === 'accountCode')
        let authority = avobjects.find(obj => obj.typeCode === 'authorityCode')
        let group = avobjects.find(obj => obj.typeCode === 'groupCode')
        if (account) {
          this.objects.accountCode.code = account.code
          await this.handleAccount(account.code)
          this.category.client = true
        } else {
          this.category.client = false
        }
        if (authority) {
          await this.handleAuthorityLevels(this.user.authorityLevelsCode)
          this.category.authority = true
          this.objects.authorityCode.code = parseInt(authority.code)
        }
        if (group) {
          this.category.group = true
          this.objects.groupCode.code = group.code
          await this.handleGroups(group.code)
        }
      }
    },
    handleClientCheckbox() {
      if (this.category.client === false) {
        this.objects.accountCode.code = null
      }
    },
    filterObject(item, queryText) {
      let fullName =
        item.firstname.toLocaleLowerCase() +
        ' ' +
        item.lastname.toLocaleLowerCase()
      return (
        fullName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    }
  },
  computed: {
    accountBeneficiariesLabel() {
      if (this.accountBeneficiaries.length === 1) {
        return `La cuenta posee ${this.accountBeneficiaries.length} Beneficiario, haga su selección`
      } else if (this.accountBeneficiaries.length === 0) {
        return `La cuenta no posee Beneficiarios`
      } else if (!this.accountBeneficiaries) {
        return `Lista de Beneficiarios`
      } else {
        return `La cuenta posee ${this.accountBeneficiaries.length} Beneficiarios, haga su selección`
      }
    },
    authoritiesLabel() {
      if (this.authorities.length === 1) {
        return `La Categoría posee ${this.authorities.length} Autoridad, haga su selección`
      } else if (this.authorities.length === 0) {
        return `La Categoría no posee Autoridades`
      } else if (!this.authorities) {
        return `Lista de Autoridades`
      } else {
        return `La Categoría posee ${this.authorities.length} Autoridades, haga su selección`
      }
    },
    hasCustomerRole() {
      return this.user.roles.some(item => item.type === 'Customer')
    }
  }
}
</script>

<style>
.v-tab {
  text-transform: none !important;
  font-weight: bold;
}
.category_row {
  padding: 0 !important;
}

.radio_buttons {
  display: flex;
  flex-direction: row;
}
</style>
